// REQUIREMENT: 4.1.18 Subscription and Payment Management

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PaymentWidget from './PaymentWidget/PaymentWidget';
import * as subsActionCreator from './store/action-creator';
import * as profileActionCreator from '../Profile/store/action-creator';
import homeTravelCam from '../../assets/device/HomeTravelCam.png';
import OverDoorCam from '../../assets/device/OverDoorCam.png';
import SRUdev from '../../assets/device/sru_display.png';
import classes from './subscription.module.css';
import ScrollArea from '../ui/ScrollBar/ScrollArea';
import CustomDialog from '../ui/CustomDialog/CustomDialog';
import ViewPlans from './ViewPlans';
import ErrorCodes from '../common/ErrorCodes';
import Notification from '../ui/Notification/Notification';
import { AuthContext } from '../../Providers/AuthProvider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { green } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from 'axios';
import { AlertContext } from '../../Providers/AlertProvider';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Checkbox from '@material-ui/core/Checkbox';

let metadata = [];

const SubscriptionTable = (props) => {
  const {
    subscriptionData,
    handleProfileMenu,
    isPaymentComplete,
    getUserSubscription,
    updateSubscription,
    apiError,
    retrieveCardDetils,
  } = props;
  const { user } = React.useContext(AuthContext);
  const userEmail = user.userEmail;

  const [deviceList, setDeviceList] = useState([]);
  const [cardModal, setCardModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [manageCard, setManageCard] = useState(false);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [cardError, setCardError] = useState(false);

  const [card, setCard] = React.useState();

  const useStyles = makeStyles({
    root: {
      borderBottom: 0,
      maxWidth: '100%',
      overflowX: 'auto',
    },
  });

  const muiClasses = useStyles();

  useEffect(() => {
    getUserSubscription(userEmail)
      .then((resp) => {
        if (resp.ErrorCode !== 'err_0') {
          setCommonError(true);
          setCommonErrorMsg(ErrorCodes[resp.ErrorCode]);
        }
      })
      .catch((err) => {
        if (err) {
          setCommonError(true);
          setCommonErrorMsg('Something went wrong!');
        }
      });
  }, []);

  useEffect(() => {
    if (isPaymentComplete === true) {
      setCardModal(false);
      getUserSubscription(userEmail)
        .then((resp) => {
          if (resp.ErrorCode !== 'err_0') {
            setCommonError(true);
            setCommonErrorMsg(ErrorCodes[resp.ErrorCode]);
          }
        })
        .catch((err) => {
          if (err) {
            setCommonError(true);
            setCommonErrorMsg('Something went wrong!');
          }
        });
    }
  }, [isPaymentComplete]);

  const handlePaymentClose = () => setCardModal(!cardModal);

  const handleViewPlan = (menu) => handleProfileMenu(menu);

  const handleSubmitSuccess = () => {
    handlePaymentClose();

    if (manageCard === false) {
      setTimeout(() => {
        setConfirmationModal(true);
      }, 4000);
    }
  };

  const handleManageCard = () => {
    setCardModal(true);
    setManageCard(true);
    retrieveCardDetils({
      email: userEmail,
      customer_id: subscriptionData.CustomerID,
    })
      .then((resp) => {
        if (resp.ErrorCode !== 'err_0') {
          setCommonErrorMsg(ErrorCodes[resp.ErrorCode]);
          setCardError(true);
        }
      })
      .catch((err) => {
        if (err) {
          setCommonError(true);
          setCommonErrorMsg('Something went wrong!');

          setCardModal(false);
        }
      });
  };

  const getCardDetails = async () => {
    try {
      const { data } = await Axios.post('/v1/users', {
        action: 'retrieve_card_details',
        data: {
          email: userEmail,
          customer_id: subscriptionData.CustomerID,
        },
      });

      setCard(data.ResponseData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => setErrorModal(false);

  const modalConfig = {
    open: errorModal,
    type: 'message',
    btnClicked: () => handleClose(),
  };

  const handleCommonError = (isError, msg) => {
    if (isError) {
      setCommonErrorMsg(msg);
      setCommonError(isError);
    }
  };

  const commonErrorBtnHandler = () => {
    setCommonErrorMsg(null);
    setCommonError(false);
  };

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: commonErrorBtnHandler,
  };

  const handleConfirmationOk = () => setConfirmationModal(false);

  const handleConfirmModal = () => setConfirmationModal(true);

  const favNotificationConfig = {
    position: 'bottomRight',
    open: confirmationModal,
    onClose: handleConfirmationOk,
  };

  React.useEffect(() => {
    if (subscriptionData.CustomerID) getCardDetails();
    if (subscriptionData.deviceData) setDeviceList(subscriptionData.deviceData);
  }, [subscriptionData]);

  const { planInfo = [], planDetails = [] } = subscriptionData;

  const modifiedPlanDetails = planInfo.map((plan) => {
    plan.year = 0;
    plan.month = 0;

    if (plan?.PlanName?.toLowerCase().includes('deluxe')) {
      const deluxe = planDetails.find((p) => p.PlanId === 'deluxe');
      const deluxeAnnual = planDetails.find((p) => p.PlanId === 'deluxe_annual');

      const key = Object.keys(deluxe.Price)[0];
      const annualKey = Object.keys(deluxeAnnual.Price)[0];

      plan.month = deluxe.Price[key];
      plan.year = deluxeAnnual.Price[annualKey] || 0;
    } else if (plan?.PlanName?.toLowerCase().includes('premium')) {
      const premium = planDetails.find((p) => p.PlanId === 'premium');
      const premiumAnnual = planDetails.find((p) => p.PlanId === 'premium_annual');

      const key = Object.keys(premium.Price)[0];
      const annualKey = Object.keys(premiumAnnual.Price)[0];

      plan.month = premium.Price[key];
      plan.year = premiumAnnual.Price[annualKey] || 0;
    }

    return plan;
  });

  return (
    <>
      {cardModal && !isPaymentComplete && (
        <PaymentWidget
          user={user}
          openVal={cardModal}
          onClose={handlePaymentClose}
          cardError={cardError}
          cardErrorMsg={commonErrorMsg}
          metadata={metadata}
          onSubmitSuccess={handleSubmitSuccess}
          manageCard={manageCard}
          handleCommonError={handleCommonError}
          handleConfirmModal={handleConfirmModal}
        />
      )}
      {apiError && <CustomDialog dialogConfig={modalConfig}>{apiError}</CustomDialog>}
      {commonError && (
        <CustomDialog dialogConfig={commonErrorDialogConfig}>
          <div>{commonErrorMsg}</div>
        </CustomDialog>
      )}
      {confirmationModal && (
        <Notification config={favNotificationConfig}>
          <div>Changes have been updated!</div>
        </Notification>
      )}
      <>
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 80 }}>
          <div className={classes.subscriptionHeading}>Subscriptions</div>
        </div>
        {!subscriptionData.deviceData && (
          <CircularProgress
            style={{
              position: 'absolute',
              left: '50%',
              right: 'auto',
              color: '#FFD200',
              marginTop: 75,
            }}
            thickness={3}
            size={40}
          />
        )}
        {subscriptionData.deviceData &&
          (deviceList.length > 0 ? (
            <div>
              <div style={{ overflow: 'auto' }}>
                <Table className={classes.tableWrapper}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: 50 }} />
                      <TableCell align="left" style={{ width: 200 }}>
                        <Typography variant="caption" color="textSecondary">
                          DEVICE NAME
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ width: 200 }}>
                        <Typography variant="caption" color="textSecondary">
                          PLAN
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          style={{ height: 50 }}
                          // color="secondary"
                          onClick={handleManageCard}
                          startIcon={<CreditCardIcon fontSize="large" />}
                        >
                          Manage card
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <ScrollArea scrollClass={classes.tableScroll} scrollMaxHeight="258px">
                  <Table className={[classes.tableWrapper, muiClasses.table].join(' ')}>
                    <TableBody>
                      {deviceList.map((device, rowInd) => (
                        <Device
                          key={rowInd}
                          device={device}
                          planDetails={planDetails}
                          planList={modifiedPlanDetails}
                          card={card}
                          getCardDetails={getCardDetails}
                          updateSubscription={updateSubscription}
                          handleProfileMenu={handleProfileMenu}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </ScrollArea>
              </div>
            </div>
          ) : (
            <ViewPlans handleViewPlan={handleViewPlan} />
          ))}
      </>
    </>
  );
};

function Device({
  device,
  planDetails,
  planList,
  card,
  getCardDetails,
  updateSubscription,
  handleProfileMenu,
}) {
  const [open, setOpen] = React.useState(false);
  const editPlan = () => {
    if (!card) getCardDetails();
    setOpen(true);
  };
  const currentSubscription = (
    planDetails.find((plan) => plan.UserSubscriptionID === device.DeviceSubscription) || {}
  ).DisplayName;

  return (
    <React.Fragment>
      <TableRow>
        <TableCell align="center" style={{ width: 50 }}>
          {
            // BUG-[#84292] In subscription page only image for portable camera
            // is present even it have SRU device in it
            device.DeviceType === 'OverDoorCamera' ? (
              <img src={OverDoorCam} alt="overdoor" style={{ height: 70 }} />
            ) : device.DeviceType === 'HomeAndTravelCamera' ? (
              <img src={homeTravelCam} alt="homeTravel" style={{ height: 70 }} />
            ) : device.DeviceType === 'SmartReadinessUnit' ? (
              <img src={SRUdev} alt="SRU" style={{ height: 70 }} />
            ) : (
              ''
            )
          }
        </TableCell>
        <TableCell align="left" style={{ width: 200 }}>
          <div className={classes.tableFontBlack}>
            {device.DeviceName}
            {device.DeviceType === 'HomeAndTravelCamera' ? (
              <div className={classes.deviceDescription}>Portable 360°</div>
            ) : device.DeviceType === 'OverDoorCamera' ? (
              <div className={classes.deviceDescription}>Portable Over-Door</div>
            ) : device.DeviceType === 'SmartReadinessUnit' ? (
              <div className={classes.deviceDescription}>Smart Readiness</div>
            ) : (
              ''
            )}
          </div>
        </TableCell>
        <TableCell align="center" style={{ width: 200 }}>
          <Chip
            label={currentSubscription}
            variant="outlined"
            onClick={() => handleProfileMenu(2)}
          />
        </TableCell>
        <TableCell align="left">
          {device?.DeviceType !== 'SmartReadinessUnit' && (
            <Tooltip title="Edit Plan">
              <IconButton onClick={() => editPlan()}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <SubscriptionModal
          device={device}
          setOpen={setOpen}
          planList={planList}
          currentSubscription={currentSubscription}
          card={card}
          planDetails={planDetails}
          updateSubscription={updateSubscription}
        />
      </Modal>
    </React.Fragment>
  );
}

function SubscriptionModal({
  device,
  setOpen,
  planList,
  currentSubscription,
  card,
  planDetails,
  updateSubscription,
}) {
  const [duration, setDuration] = useState('month');
  const [selectedPlan, setSelectedPlan] = React.useState();
  const [submitting, setSubmitting] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [action, setAction] = React.useState('');

  const { user } = React.useContext(AuthContext);
  const { setAlert } = React.useContext(AlertContext);

  async function handleSubscribe() {
    try {
      setSubmitting(true);

      let plan =
        duration === 'month' || selectedPlan.PlanName === 'Free'
          ? planDetails.find((e) => e.PlanName === selectedPlan.PlanName)
          : planDetails.find((e) => e.PlanName === `${selectedPlan.PlanName} Annual`);

      const body = {
        planId: plan.PlanId,
        deviceId: device.ThingName,
        email: user.userEmail,
      };

      await updateSubscription(body);
      setSubmitting(false);
      setOpen(false);
      setAlert({
        variant: 'success',
        message: 'Success!',
      });
    } catch (error) {
      setSubmitting(false);
      const axiosError = error?.response?.data?.error;
      setAlert({
        variant: 'error',
        message: axiosError || error,
      });
    }
  }

  React.useEffect(() => {
    if (!selectedPlan) return;

    const currentSubscriptionObj = planDetails.find(
      (p) => p.DisplayName.toLowerCase() === currentSubscription.toLowerCase()
    );
    const selectedSubscriptionObj = planDetails.find(
      (p) => p.DisplayName.toLowerCase() === selectedPlan.DisplayName.toLowerCase()
    );

    const currentPlanId = currentSubscriptionObj.PlanId;
    const selectedPlanId = selectedSubscriptionObj.PlanId;

    if (currentPlanId !== 'free' && selectedPlanId === 'free') setAction('renderDowngradeToFree');
    else if (currentPlanId.includes('deluxe') && selectedPlanId.includes('premium'))
      setAction('renderUpgradeDeluxeToPremium');
    else if (currentPlanId.includes('premium') && selectedPlanId.includes('deluxe'))
      setAction('renderDowngradeToDeluxe');
    else setAction('');
  }, [selectedPlan]);

  let text = null;
  if (action === 'renderDowngradeToFree') text = renderDowngradeToFree(setChecked, checked);
  else if (action === 'renderUpgradeDeluxeToPremium') text = renderUpgradeDeluxeToPremium();
  else if (action === 'renderDowngradeToDeluxe') text = renderDowngradeToDeluxe();

  const disableConfirm = action === 'renderDowngradeToFree' && !checked;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper>
        <header style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton aria-label="close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </header>
        <body style={{ padding: '0 30px 30px' }}>
          <div style={{ display: 'flex', marginLeft: 5 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 240,
                margin: '0 10px',
              }}
            >
              {device.DeviceType === 'OverDoorCamera' ? (
                <img src={OverDoorCam} alt="overdoor" style={{ height: 80, paddingRight: 10 }} />
              ) : device.DeviceType === 'HomeAndTravelCamera' ? (
                <img src={homeTravelCam} alt="homeTravel" />
              ) : (
                ''
              )}

              <div className={classes.tableFontBlack}>
                {device.DeviceName}
                {device.DeviceType === 'HomeAndTravelCamera' ? (
                  <div className={classes.deviceDescription}>Portable 360°</div>
                ) : device.DeviceType === 'OverDoorCamera' ? (
                  <div className={classes.deviceDescription}>Portable Over-Door</div>
                ) : device.DeviceType === 'SmartReadinessUnit' ? (
                  <div className={classes.deviceDescription}>Smart Readiness</div>
                ) : (
                  ''
                )}
                <Typography variant="caption" color="textSecondary">
                  Current Plan: {currentSubscription}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 240,
                margin: '0 10px',
              }}
            >
              <ToggleButtonGroup
                value={duration}
                exclusive
                onChange={(e, v) => v && setDuration(v)}
                aria-label="text alignment"
              >
                <ToggleButton value="month" aria-label="left aligned">
                  Monthly
                </ToggleButton>
                <ToggleButton value="year" aria-label="centered">
                  Annual
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: 240,
                margin: '0 10px',
              }}
            >
              {card ? (
                <>
                  {' '}
                  <Typography variant="caption" color="textPrimary">
                    Payment method
                  </Typography>
                  <Typography variant="caption" color="textPrimary">
                    ending in {card ? card?.last4 : '...'}
                  </Typography>{' '}
                </>
              ) : (
                <>
                  {' '}
                  <Typography variant="body2" color="error">
                    {' '}
                    Please add card to upgrade your subscription{' '}
                  </Typography>{' '}
                </>
              )}
            </div>
          </div>

          <div style={{ display: 'flex', padding: '10px 0px' }}>
            {planList.map((plan) => {
              let desc = '';
              switch (plan.PlanName) {
                case 'Free':
                  desc = '';
                  break;
                case 'Deluxe':
                  desc = 'per camera';
                  break;
                case 'Premium':
                  desc = 'up to 3 cameras';
                  break;
                default:
                  break;
              }
              return (
                <SubscriptionCard
                  key={plan.PlanId}
                  plan={plan}
                  duration={duration}
                  desc={desc}
                  setSelectedPlan={setSelectedPlan}
                  selectedPlan={selectedPlan}
                />
              );
            })}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {text}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              variant="outlined"
              style={{ width: 250, height: 60 }}
              disabled={!selectedPlan || !card || submitting || disableConfirm}
              onClick={handleSubscribe}
            >
              {submitting ? <CircularProgress style={{ color: '#FFD200' }} /> : 'Confirm'}
            </Button>
          </div>
        </body>
      </Paper>
      ;
    </div>
  );
}

function SubscriptionCard({ plan, duration, desc, setSelectedPlan, selectedPlan = {} }) {
  // const isCurrentSubscription = currentSubscription.toLowerCase() === plan.PlanName.toLowerCase();
  const isSelected = plan.PlanName === selectedPlan.PlanName;

  let title = 'free';
  if (plan.PlanName.toLowerCase().includes('deluxe'))
    title = duration === 'month' ? 'Deluxe' : 'Deluxe Annual';
  else if (plan.PlanName.toLowerCase().includes('premium'))
    title = duration === 'month' ? 'Premium' : 'Premium Annual';

  return (
    <div
      style={{
        border: '2px solid #bbb',
        borderRadius: 5,
        height: 300,
        width: 240,
        margin: 10,
        background: isSelected ? green[100] : '',
      }}
    >
      <div
        style={{
          height: '80%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="subtitle1" style={{ textTransform: 'uppercase' }}>
          {title}
        </Typography>
        <Typography variant="h3" style={{ margin: 15 }}>
          ${plan[duration]}
        </Typography>
        <Typography variant="subtitle2" style={{ textTransform: 'uppercase' }}>
          Per {duration}
        </Typography>
        {desc && <Typography variant="caption">{desc}</Typography>}
      </div>
      <div
        style={{
          height: '20%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {isSelected ? (
          <Button
            variant="outlined"
            style={{ height: 40, width: 150 }}
            onClick={() => setSelectedPlan(undefined)}
          >
            <CheckIcon />
          </Button>
        ) : (
          <Button
            variant="outlined"
            style={{ height: 40, width: 150 }}
            onClick={() => setSelectedPlan(plan)}
          >
            Select
          </Button>
        )}
      </div>
    </div>
  );
}

const waningTextStyle = {
  maxWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 20,
};

function renderDowngradeToFree(setChecked, checked) {
  return (
    <div style={waningTextStyle}>
      <Typography variant="caption" color="textSecondary">
        You're about to downgrade your paid subscription to the free version. You'll still have some
        great features but the following items will be removed from your subscription:
      </Typography>
      <Typography variant="caption" color="textSecondary">
        <ul>
          <li>Cloud storage for your videos.</li>
          <li>Rich notifications and smart detection alerts.</li>
          <li>Customized activity zones.</li>
          <li style={{ marginBottom: '10px' }}>The ability to review, share and save videos.</li>
        </ul>
      </Typography>

      <Typography variant="caption" color="textSecondary">
        Please note that this change will take effect immediately. Our team will issue a pro-rated
        refund based on the number of days left in your billing cycle. You should see that refund
        within 3 to 5 business days. Thanks for being part of the BodyGuardz family.
        <br></br>
        You will need to reconfigure your activity triggers settings for your camera.
      </Typography>
      <div>
        <Checkbox
          style={{ color: '#FFD200' }}
          value={checked}
          onClick={(event) => setChecked(event.target.checked)}
        />{' '}
        <Typography variant="caption" color="textSecondary">
          I would like to continue to downgrade my subscription.
        </Typography>
      </div>
    </div>
  );
}

function renderDowngradeToDeluxe() {
  return (
    <div style={waningTextStyle}>
      <Typography variant="caption" color="textSecondary">
        You're about to downgrade your premium subscription to the deluxe version.
      </Typography>
      <Typography variant="caption" color="textSecondary">
        Please note that this change will take effect immediately. Our team will issue a pro-rated
        refund based on the number of days left in your billing cycle. You should see that refund
        within 3 to 5 business days. Thanks for being part of the BodyGuardz family.
        <br></br>
        You will need to reconfigure your activity triggers settings for your camera.
      </Typography>
    </div>
  );
}

function renderUpgradeDeluxeToPremium() {
  return (
    <div style={waningTextStyle}>
      <Typography variant="caption" color="textSecondary">
        Congratulations you're about to upgrade your deluxe subscription to the premium version.
      </Typography>
      <Typography variant="caption" color="textSecondary">
        Please note that this change will take effect immediately. Our team will issue a pro-rated
        refund based on the number of days left in your billing cycle. You should see that refund
        within 3 to 5 business days. Thanks for being part of the BodyGuardz family.
        <br></br>
        You will need to reconfigure your activity triggers settings for your camera.
      </Typography>
    </div>
  );
}

const mapStateToProps = (state) => ({
  subscriptionData: state.subscriptionReducer.userSubscription,
  isPaymentComplete: state.subscriptionReducer.isPaymentComplete,
  paymentSourceStatus: state.subscriptionReducer.paymentSourceStatus,
  apiError: state.subscriptionReducer.apiError,
});

const mapDispatchToProps = (dispatch) => ({
  handleProfileMenu: (data) => dispatch(profileActionCreator.setProfileMenu(data)),
  getUserSubscription: (emailID) => dispatch(subsActionCreator.getUserSubscription(emailID)),
  updateSubscription: (data) => dispatch(subsActionCreator.updateSubscription(data)),
  retrieveCardDetils: (data) => dispatch(subsActionCreator.retrieveCardDetils(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscriptionTable));
