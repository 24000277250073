// REQUIREMENT: 4.1.17 User Management

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InviteUser from './InviteUser';
import EditUser from './EditUser';
import CustomDialog from '../../ui/CustomDialog/CustomDialog';
import LoadingSpinner from '../../ui/LoadingSpinner/LoadingSpinner';
import ButtonElem from '../../ui/Button/Button';
import * as deviceActionCreator from '../../DeviceList/store/action-creator';
import * as homeActionCreator from '../store/action-creator';
import classes from '../ManageHomes.module.css';
import AuthorisedUser from './AuthorisedUser';
import { MANAGE_HOMES } from '../../../constants/routes';
import editClasses from './EditHome.module.css';
import { forwardBlueIcon } from '../../ui/Icons/Icons';
import Notification from '../../ui/Notification/Notification';
import msgConstant from '../../common/textConstants';
import ErrorCodes from '../../common/ErrorCodes';
import { TrunLine } from '../../common/utils';
import StringTooltip from '../../ui/StringTooltip';
import TimeZone from '../TimeZone/TimeZone';
import { AuthContext } from '../../../Providers/AuthProvider';

const EditHomeForm = (props) => {
  const { user } = React.useContext(AuthContext);
  const email = user.userEmail;
  const ownerName = user.userName;
  const IdentityId = user.IdentityId;
  const {
    Location,
    homeSelected,
    onSetHomeData,
    deviceResp,
    homes,
    homeInfo,
    onSetSelectedHome,
    history,
    onInviteUser,
    onEditUser,
    userSelected,
    onDeleteUser,
    onEditHome,
    onResendInvite,
  } = props;
  const [position, setPosition] = useState({ lat: '', lng: '' });
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [removeModal, setRemoveModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [devices, setDevices] = useState(null);
  const [homeName, setHomeName] = useState('');
  const [userModal, setUserModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [oneHomeModal, setOneHomeModal] = useState(false);
  const [hasDevicesModal, setHasDevicesModal] = useState(false);
  const [invitedUser, setInvitedUser] = useState(null);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);
  const [noDeviceModal, setNoDeviceModal] = useState(null);
  const [membersData, setMembersData] = useState(null);
  const [deleteOnEditModal, setDeleteOnEditModal] = useState(false);
  const [btnStatus, setBtnStatus] = useState(null);
  const [isEmptyHomeName, setIsEmptyHomeName] = useState(false);
  const [userName, setUserName] = useState(null);
  const [open, setOpen] = useState(false);
  const [zoneName, setZoneName] = useState(null);
  const [notificationMsg, setNotificationMsg] = useState(null);

  const inviteClassList = ['CustomInput', 'ManageHomeFormInput', 'Font13', editClasses.invite].join(
    ' '
  );

  const updateMapForm = (Locate) => {
    setAddress(Locate.home_address.split(',')[0]);
    setCity(Locate.city);
    setState(Locate.state);
    setZipCode(Locate.zipCode);
    setHomeName(homeSelected.home_name);
    setPosition({ lat: Locate.latitude, lng: Locate.longitude });
  };

  useEffect(() => {
    setMembersData(homeSelected.members);
  }, [homeSelected]);

  const toggleSpinner = (data) => setSpinner(data);

  useEffect(() => {
    updateMapForm(Location);
  }, [Location]);

  const setInvitedDevices = (list) => setDevices(list);
  const setEmail = (emailId) => setInvitedUser(emailId);
  const setInvitedUserName = (name) => setUserName(name);

  const onSaveHandler = () => {
    toggleSpinner(true);
    const add = address;
    const editedCity = city;
    const editedState = state;
    const editedZipCode = zipCode;
    const newadd = `${add}&?${editedCity}&?${editedState}&?${editedZipCode}`;

    const params = {
      action: 'edithome',
      data: {
        email,
        home_details: {
          home_id: props.homeSelected.home_id,
          home_name: homeName.trim(),
          latitude: position.lat.toString(),
          longitude: position.lng.toString(),
          home_address: newadd,
          region_pair: zoneName,
        },
      },
    };
    onEditHome(params).then(
      (resp) => {
        toggleSpinner(false);
        const respCode = resp.data.ResponseCode;
        const errCode = resp.data.ErrorCode;
        if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
          setCommonError(!commonError);
          setCommonErrorMsg(msgConstant.EDIT_HOME_ERROR);
        } else {
          setOpen(true);
          setNotificationMsg(msgConstant.EDIT_HOME_SUCCESS);
          setTimeout(() => {
            onSetHomeData(deviceResp, homeName.trim(), props.homeSelected.home_id);
            if (props.path !== '\\managehomes') {
              props.onGetDevices(email, IdentityId, deviceResp.deviceData?.HomeId);
            }
            history.push(props.path);
          }, 1000);
        }
      },
      () => {
        toggleSpinner(false);
        setCommonError(!commonError);
        setCommonErrorMsg('Somthing went wrong');
      }
    );
  };

  const onHomeChange = (evt) => {
    if (evt.target.value.replace(/\s/g, '').length < 0) {
      setIsEmptyHomeName(true);
    } else {
      setIsEmptyHomeName(false);
      setHomeName(evt.target.value);
    }
  };

  const onleadingSpace = (e) => {
    if (e.keyCode === 32 && !homeName.replace(/\s/g, '').length) {
      e.preventDefault();
    }
  };

  const onAddressChange = (evt) => setAddress(evt.target.value);
  const onCityChange = (evt) => setCity(evt.target.value);
  const onStateChange = (evt) => setState(evt.target.value);
  const onZipCodeChange = (evt) => setZipCode(evt.target.value);

  const onCancelHandler = () => history.push(props.path);

  const commonErrorBtnHandler = () => setCommonError(!commonError);

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: commonErrorBtnHandler,
  };

  const btnHandler = (hasAction) => {
    if (hasAction) {
      setRemoveModal(!removeModal);
      toggleSpinner(true);
      const params = {
        action: 'deleteHome',
        data: { email, home_id: homeSelected.home_id },
      };
      onSetSelectedHome(params).then(
        (resp) => {
          toggleSpinner(false);
          const respCode = resp.data.ResponseCode;
          const errCode = resp.data.ErrorCode;
          if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
            setCommonError(!commonError);
            setCommonErrorMsg(msgConstant.REMOVE_HOME_ERROR);
          } else {
            setOpen(true);
            setNotificationMsg(msgConstant.REMOVE_HOME_SUCESS);
            setTimeout(() => {
              setRemoveModal(!removeModal);
              history.push(MANAGE_HOMES);
              props.onSelectedHome(null);
              props.onSetDeletedHomedata(null);
              if (props.path !== '\\managehomes') {
                props.onGetDevices(email, IdentityId, deviceResp.deviceData?.HomeId);
              }
            }, 1500);
          }
        },
        () => {
          toggleSpinner(false);
          setCommonError(!commonError);
          setCommonErrorMsg(`${msgConstant.COMMON_ERROR}`);
        }
      );
    } else setRemoveModal(!removeModal);
  };

  const setNoDeviceBtnHandler = () => setNoDeviceModal(!noDeviceModal);

  const noDeviceDialogConfig = {
    open: noDeviceModal,
    textAlign: 'left',
    btnClicked: setNoDeviceBtnHandler,
  };

  // #84228] Edit home giving blank page  for the newly created home

  const inviteHandler = () => {
    if (homeInfo.deviceData.DeviceList.length) {
      setModal(!modal);
    } else setNoDeviceModal(!noDeviceModal);
  };

  const inviteHandlerFunction = () => {
    if (Array.isArray(devices) && devices.length && invitedUser && email) {
      toggleSpinner(true);
      const param = {
        action: 'invite_user',
        data: {
          email,
          user: invitedUser,
          device_list: devices,
          owner_name: ownerName,
          user_name: userName,
        },
      };
      onInviteUser(param).then(
        (response) => {
          const respCode = response.data.ResponseCode;
          const errCode = response.data.ErrorCode;
          if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
            toggleSpinner(false);
            setCommonError(!commonError);
            setCommonErrorMsg(msgConstant.INVITE_USER_ERROR);
          } else {
            setOpen(true);
            setNotificationMsg(msgConstant.INVITE_USER_SUCCESS);
            setTimeout(() => {
              setModal(!modal);
              setDevices(null);
              Axios.get(`/v1/users?email=${param.data.email}&action=listhomes`)
                .then((resp) => {
                  if (resp.data.ErrorCode === 'err_0') {
                    const updatedHome = resp.data.ResponseData.find(
                      (val) => val.home_id === homeSelected.home_id
                    );
                    props.onSelectedHome(updatedHome);
                    toggleSpinner(false);
                  } else {
                    toggleSpinner(false);
                    setCommonError(!commonError);
                    setCommonErrorMsg(`${ErrorCodes[resp.data.ErrorCode]}`);
                  }
                })
                .catch(() => {
                  toggleSpinner(false);
                  setCommonError(!commonError);
                  setCommonErrorMsg(`${msgConstant.COMMON_ERROR}`);
                });
            }, 500);
          }
        },
        () => {
          setDevices(null);
          toggleSpinner(false);
          setCommonError(!commonError);
          setCommonErrorMsg(`${msgConstant.COMMON_ERROR}`);
        }
      );
    } else if (invitedUser === '' && Array.isArray(devices) && devices.length) {
      setCommonErrorMsg(msgConstant.INVITE_USER_EMAIL_EMPTY);
      setCommonError(!commonError);
    } else if (invitedUser && devices === null) {
      setCommonErrorMsg(msgConstant.INVITE_USER_SINGLE_DEVICE);
      setCommonError(!commonError);
    } else {
      setCommonErrorMsg('UserEmail and Devices List cannot be empty');
      setCommonError(!commonError);
    }
  };

  const btnInviteHandler = (hasAction) => {
    if (hasAction) {
      inviteHandlerFunction();
    } else setModal(!modal);
  };

  const removeModalHandler = () => {
    if (homes.length === 1) {
      setOneHomeModal(!oneHomeModal);
    } else if (homeInfo.deviceData.DeviceList.length) {
      setHasDevicesModal(!hasDevicesModal);
    } else {
      setRemoveModal(!removeModal);
    }
  };

  const removeModalConfig = {
    open: removeModal,
    btnClicked: btnHandler,
    btnText: 'Remove',
    title: 'Remove Home',
    disabled: true,
    type: 'action',
  };

  const oneHomeBtnHandler = () => setOneHomeModal(!oneHomeModal);

  const oneHomeDialogConfig = {
    open: oneHomeModal,
    textAlign: 'left',
    title: 'Unable to remove home',
    btnClicked: oneHomeBtnHandler,
  };

  const hasDeviceBtnHandler = () => setHasDevicesModal(!hasDevicesModal);

  const hasDeviceDialogConfig = {
    open: hasDevicesModal,
    textAlign: 'left',
    title: 'Unable to remove home',
    btnClicked: hasDeviceBtnHandler,
  };

  const userDeleteHandler = () => setUserModal(!userModal);
  const editUserBtnHandler = () => setEditModal(!editModal);

  const userDeletedSuccess = (resp, type) => {
    if (type === 'delete') {
      userDeleteHandler();
    } else {
      setDeleteOnEditModal(!deleteOnEditModal);
      editUserBtnHandler();
    }
    toggleSpinner(false);
    const newMembersData = { ...homeSelected };
    const unRemovedUser = Object.keys(membersData).filter((val) => val !== userSelected.user);
    const filtered = Object.keys(membersData)
      .filter((key) => unRemovedUser.includes(key))
      .reduce((obj, key) => {
        obj[key] = membersData[key];
        return obj;
      }, {});
    newMembersData.members = filtered;
    props.onSelectedHome(newMembersData);
  };

  const userDeleteFailure = () => {
    userDeleteHandler();
    commonErrorBtnHandler();
    setCommonErrorMsg(`${msgConstant.COMMON_ERROR}`);
    toggleSpinner(false);
  };

  const deleteHandler = (params, type) => {
    toggleSpinner(true);
    onDeleteUser(params).then(
      (resp) => {
        const respCode = resp.data.ResponseCode;
        const errCode = resp.data.ErrorCode;
        if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
          toggleSpinner(false);
          setCommonError(!commonError);
          setCommonErrorMsg(msgConstant.REMOVE_USER_ERROR);
        } else {
          userDeletedSuccess(resp, type);
        }
      },
      (err) => userDeleteFailure(err)
    );
  };

  const deleteUserOnEditHandler = (hasAction) => {
    if (hasAction) {
      const params = {
        action: 'unshare_device',
        data: { user: userSelected.user, email },
      };
      deleteHandler(params, 'edit');
    } else setDeleteOnEditModal(!deleteOnEditModal);
  };

  const deleteOnEditDialogConfig = {
    open: deleteOnEditModal,
    btnClicked: deleteUserOnEditHandler,
    btnText: 'Remove',
    title: 'Remove User',
    disabled: true,
    type: 'action',
  };

  const editHandler = (params) => {
    toggleSpinner(true);
    onEditUser(params).then(
      (resp) => {
        toggleSpinner(false);
        const respCode = resp.data.ResponseCode;
        const errCode = resp.data.ErrorCode;
        if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
          editUserBtnHandler();
          setCommonError(!commonError);
          setCommonErrorMsg(msgConstant.INVITE_USER_EDIT_ERROR);
        } else {
          setOpen(true);
          setNotificationMsg(msgConstant.INVITE_USER_EDIT_SUCCESS);
          setTimeout(() => {
            editUserBtnHandler();
          }, 500);
        }
      },
      () => {
        toggleSpinner(false);
        setCommonError(!commonError);
        setCommonErrorMsg(`${msgConstant.COMMON_ERROR}`);
      }
    );
  };

  const userBtnHandler = (hasAction) => {
    if (hasAction) {
      const params = {
        action: 'unshare_device',
        data: { email, user: userSelected.user },
      };
      deleteHandler(params, 'delete');
    } else setUserModal(!userModal);
  };

  const userDialogConfig = {
    open: userModal,
    btnClicked: userBtnHandler,
    btnText: 'Remove',
    title: 'Remove User',
    disabled: true,
    type: 'action',
  };

  const editUserHandler = (hasAction) => {
    if (hasAction) {
      const deviceIdList = [];
      homeInfo.deviceData.DeviceList.map((each) => deviceIdList.push(each.DeviceId));
      const unsharedDevices = deviceIdList.filter((x) => !devices.includes(x));
      const sharedDevices = deviceIdList.filter((x) => devices.includes(x));
      if (sharedDevices.length) {
        const param = {
          action: 'edit_invited_user',
          data: {
            user: userSelected.user,
            email,
            share_device_list: sharedDevices,
            unshare_device_list: unsharedDevices,
          },
        };
        const initialHomeInfo = { ...homeSelected };
        initialHomeInfo.members[userSelected.user] = sharedDevices;
        props.onSelectedHome(initialHomeInfo);
        editHandler(param);
      } else {
        setDeleteOnEditModal(!deleteOnEditModal);
      }
    } else {
      editUserBtnHandler();
    }
  };

  const editUserConfig = {
    title: 'Edit user',
    textAlign: 'left',
    btnAlign: 'left',
    btnText: 'Edit',
    btnColor: 'YellowButton',
    disabled: true,
    open: editModal,
    btnClicked: editUserHandler,
    type: 'action',
  };

  const dialogConfig = {
    title: 'Invite user',
    textAlign: 'left',
    btnAlign: 'left',
    btnText: 'Send invite',
    btnColor: 'YellowButton',
    open: modal,
    btnClicked: btnInviteHandler,
    disabled: btnStatus,
    type: 'action',
    cancelBtnWidth: '127px',
    doneBtnWidth: '127px',
  };

  let userListElem;

  const inviteUsersData = () => {
    const users = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item in membersData) {
      if (Object.prototype.hasOwnProperty.call(membersData, item)) {
        users.push(item);
      }
    }
    userListElem = users.map((val) => (
      <AuthorisedUser
        key={val}
        userId={val}
        editUser={editUserBtnHandler}
        deleteUser={userDeleteHandler}
        deviceLists={membersData[val]}
      />
    ));
  };
  const handleClose = () => setOpen(false);

  const config = {
    position: 'bottomRight',
    open,
    onClose: handleClose,
  };

  const resendInviteHandler = (info) => {
    const params = {
      action: 'resend_invite',
      data: {
        confirmation_code: info.confirmation_code,
        owner_name: ownerName,
      },
    };
    onResendInvite(params).then(
      (resp) => {
        const respCode = resp.data.ResponseCode;
        const errCode = resp.data.ErrorCode;
        if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
          setCommonError(!commonError);
          setCommonErrorMsg(msgConstant.RESEND_INVITE_ERROR);
        } else {
          setOpen(true);
          setNotificationMsg(msgConstant.RESEND_INVITE_SUCCESS);
        }
      },
      () => {
        setCommonError(!commonError);
        setCommonErrorMsg(`${msgConstant.RESEND_GENERIC}`);
      }
    );
  };

  let pendingUsersElem;

  const pendingUsersData = () => {
    pendingUsersElem = homeSelected.pending_invites.map((val) => (
      <div style={{ position: 'relative', paddingLeft: '7px' }} key={val.confirmation_code}>
        <StringTooltip val={val.user_email} length={27}>
          <Input
            className={inviteClassList}
            value={val.user_email.length > 27 ? TrunLine(val.user_email, 27) : val.user_email}
            readOnly
          />
        </StringTooltip>
        <span
          className={editClasses.resendInvite}
          onClick={() => resendInviteHandler(val)}
          role="presentation"
        >
          Resend invite
          <img src={forwardBlueIcon} alt=">" style={{ width: '8px', paddingLeft: '5px' }} />
        </span>
      </div>
    ));
  };

  if (homeSelected.pending_invites) {
    pendingUsersData();
  }

  const onInviteBtnSet = (val) => setBtnStatus(val);

  if (membersData) {
    if (membersData) {
      inviteUsersData();
    } else {
      userListElem = <div>No Users</div>;
    }
  }

  let ErrorDisplay;

  if (homeName === '') {
    ErrorDisplay = msgConstant.NICKNAME_EMPTY;
  } else if (homeName.length > 256) {
    ErrorDisplay = msgConstant.NICKNAME_MAX_CHAR;
  } else if (homeName.length < 4) {
    ErrorDisplay = msgConstant.NICKNAME_MIN_CHAR;
  }

  const getUpdatedZone = (name) => setZoneName(name);
  return (
    <>
      <LoadingSpinner spinnerConfig={{ showSpinner: spinner }} />
      <Grid container direction="column">
        <Grid item xs={10}>
          <div style={{ marginBottom: '20px', paddingLeft: '17px', width: '89%' }}>
            <Input
              name="home"
              className="CustomInput ManageHomeFormInput"
              onChange={(evt) => onHomeChange(evt)}
              onKeyDown={(e) => onleadingSpace(e)}
              value={homeName || ''}
              placeholder="Home Name"
              fullWidth
            />
            <p
              style={{
                visibility:
                  homeName === '' || homeName.length > 20 || homeName.length < 4
                    ? 'visible'
                    : 'hidden',
                color: '#B52A2A',
                fontSize: '14px',
              }}
            >
              {ErrorDisplay}
            </p>
          </div>
        </Grid>
        <Grid item xs={10} style={{ paddingLeft: '8px' }}>
          <p className={editClasses.addressFields}>Location</p>
          <div className={editClasses.addressFields}>
            <Input
              className="CustomInput ManageHomeFormInput Font14"
              onChange={(evt) => onAddressChange(evt)}
              value={address || ''}
              placeholder="Address"
              fullWidth
            />
            <Input
              className="CustomInput ManageHomeFormInput Font14"
              onChange={(evt) => onCityChange(evt)}
              value={city || ''}
              placeholder="City"
              fullWidth
            />
            <Input
              className="CustomInput ManageHomeFormInput Font14"
              onChange={(evt) => onStateChange(evt)}
              value={state || ''}
              placeholder="State"
              fullWidth
            />
            <Input
              className="CustomInput ManageHomeFormInput Font14"
              onChange={(evt) => onZipCodeChange(evt)}
              value={zipCode || ''}
              placeholder="ZIP Code"
              fullWidth
            />
            <TimeZone coordinate={position} updatedZone={getUpdatedZone} />
          </div>
        </Grid>
        <Grid item xs={10}>
          <div style={{ paddingLeft: '8px' }}>
            <p className={editClasses.authStyle}> Authorized Users</p>
            <div style={{ marginLeft: 10 }}>
              <StringTooltip val={email} length={27}>
                {/* #84310] In invite page , logged in users mail id is in different format  */}
                <Input
                  className={inviteClassList}
                  value={email.length > 27 ? `${TrunLine(email, 27)}(you)` : `${email}(you)`}
                  readOnly
                />
              </StringTooltip>
              {userListElem}
            </div>
            <div className={editClasses.inviteUser}>
              <span
                onClick={inviteHandler}
                className={editClasses.inviteUserText}
                role="presentation"
              >
                Invite users
                <img
                  src={forwardBlueIcon}
                  alt=">"
                  width="7"
                  height="7"
                  style={{ padding: 5, verticalAlign: 'middle' }}
                />
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={10}>
          {homeSelected.pending_invites && (
            <div style={{ marginLeft: '10px' }}>
              <p className={editClasses.authStyle}> Pending Users</p>
              {pendingUsersElem}
            </div>
          )}
        </Grid>
        <br />
        <div className={classes.buttonSet} style={{}}>
          <ButtonElem
            btnColor="YellowButton"
            value="Save"
            btnType="submit"
            style={{ width: 127, marginRight: '20px' }}
            clicked={onSaveHandler}
            isbtndisabled={
              isEmptyHomeName || homeName.length > 20 || homeName.length < 4 ? true : undefined
            }
          />
          <ButtonElem
            btnColor="GreyButton"
            value="Cancel"
            btnType="submit"
            style={{ width: 127 }}
            clicked={onCancelHandler}
          />
        </div>
        <div className={editClasses.removeHome}>
          <span onClick={removeModalHandler} role="presentation">
            Remove Home
          </span>
        </div>
        {removeModal && (
          <CustomDialog dialogConfig={removeModalConfig}>
            <div>{msgConstant.REMOVE_HOME_CHECK}</div>
          </CustomDialog>
        )}
        {/* #84228 : Edit home giving blank page  for the newly created home
           (trackeritem-84228)  */}
        {modal && (
          <CustomDialog dialogConfig={dialogConfig}>
            <InviteUser
              setDeviceFunction={setInvitedDevices}
              invited={setEmail}
              invitedUserName={setInvitedUserName}
              btnDisabled={onInviteBtnSet}
            />
          </CustomDialog>
        )}
        {editModal && (
          <CustomDialog dialogConfig={editUserConfig}>
            <EditUser setDeviceFunction={setInvitedDevices} />
          </CustomDialog>
        )}
        {userModal && (
          <CustomDialog dialogConfig={userDialogConfig}>
            <div>{msgConstant.REMOVE_USER_CHECK}</div>
          </CustomDialog>
        )}
        {oneHomeModal && (
          <CustomDialog dialogConfig={oneHomeDialogConfig}>
            <div>{msgConstant.SINGLE_HOME_CHECK}</div>
          </CustomDialog>
        )}
        {hasDevicesModal && (
          <CustomDialog dialogConfig={hasDeviceDialogConfig}>
            <div>{msgConstant.REMOVE_DEVICES_CHECK}</div>
          </CustomDialog>
        )}
        {noDeviceModal && (
          <CustomDialog dialogConfig={noDeviceDialogConfig}>
            <div>{msgConstant.INVITE_NO_DEVICES_CHECK}</div>
          </CustomDialog>
        )}
        {commonError && (
          <CustomDialog dialogConfig={commonErrorDialogConfig}>
            <div>{commonErrorMsg}</div>
          </CustomDialog>
        )}
        {deleteOnEditModal && (
          <CustomDialog dialogConfig={deleteOnEditDialogConfig}>
            <div>{msgConstant.REMOVE_ALL_DEVICES_EDIT}</div>
          </CustomDialog>
        )}
        {open && <Notification config={config}>{notificationMsg}</Notification>}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  homeSelected: state.homeListReducer.selectedHome,
  deviceResp: state.deviceReducer.deviceResp,
  homes: state.homeListReducer.homeInfo,
  homeInfo: state.homeListReducer.selectedHomeInfo,
  userSelected: state.homeListReducer.selectedUser,
});

const mapDispatchToProps = (dispatch) => ({
  onSetHomeData: (data, name, id) => dispatch(deviceActionCreator.updateHomeInfo(data, name, id)),
  onSetSelectedHome: (data) => homeActionCreator.setSelectedHome(data),
  onInviteUser: (data) => homeActionCreator.inviteUser(data),
  onEditUser: (data) => homeActionCreator.editUser(data),
  onDeleteUser: (data) => homeActionCreator.deleteUser(data),
  onResendInvite: (data) => homeActionCreator.resendInvite(data),
  onSelectedHome: (data) => dispatch(homeActionCreator.selectedHome(data)),
  onEditHome: (data) => homeActionCreator.editHome(data),
  onSetDeletedHomedata: (data) => dispatch(homeActionCreator.selectedHomeData(data)),
  onGetDevices: (email, IdentityId, homeid) =>
    dispatch(deviceActionCreator.getDevices(email, IdentityId, homeid)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditHomeForm));
