// REQUIREMENT: 4.1.18 Subscription and Payment Management

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import * as iconEle from '../ui/Icons/Icons';
import ButtonElem from '../ui/Button/Button';
import * as profileActionCreator from '../Profile/store/action-creator';
import { SmartLifePlansTooltip } from './TableTooltip/TableTooltip';
import planInfoMappingKeys from './planInfoMapping';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

const SmartLifePlans = (props) => {
  const { userSubscription, onSelectedMenu } = props;
  const [planList, setPlanList] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [planInfoKeys, setPlanInfoKeys] = useState([]);
  const [planInfoValues, setPlanInfoValues] = useState([]);
  // const [headerColWidth, setHeaderColWidth] = useState('');
  const [bodyColWidth, setBodyColWidth] = useState('');
  const [durration, setDurration] = useState('month');

  useEffect(() => {
    if (Object.keys(userSubscription).length === 0 && userSubscription.constructor === Object) {
      setLoader(true);
    } else {
      let planInfoArr = [];
      if (userSubscription.planInfo) {
        const { planInfo, planDetails } = userSubscription;
        const modifiedPlanDetails = planInfo.map((plan) => {
          plan.year = 0;
          plan.month = 0;

          if (plan?.PlanName?.toLowerCase().includes('deluxe')) {
            const deluxe = planDetails.find((p) => p.PlanId === 'deluxe');
            const deluxeAnnual = planDetails.find((p) => p.PlanId === 'deluxe_annual');

            const key = Object.keys(deluxe.Price)[0];
            const annualKey = Object.keys(deluxeAnnual.Price)[0];

            plan.month = deluxe.Price[key];
            plan.year = deluxeAnnual.Price[annualKey] || 0;
          } else if (plan?.PlanName?.toLowerCase().includes('premium')) {
            const premium = planDetails.find((p) => p.PlanId === 'premium');
            const premiumAnnual = planDetails.find((p) => p.PlanId === 'premium_annual');

            const key = Object.keys(premium.Price)[0];
            const annualKey = Object.keys(premiumAnnual.Price)[0];

            plan.month = premium.Price[key];
            plan.year = premiumAnnual.Price[annualKey] || 0;
          }

          return plan;
        });
        setPlanList(modifiedPlanDetails);
        planInfoArr = JSON.parse(JSON.stringify(userSubscription.planInfo));
      }
      const temp = [];
      planInfoArr.forEach((each) => {
        [
          'PlanDescription',
          'Rank',
          'PlanName',
          'PlanStatus',
          'DisplayName',
          'BillingInterval',
          'PricingModel',
          'month',
          'year',
        ].forEach((e) => delete each[e]);
      });
      planInfoArr.forEach((each) => {
        // eslint-disable-next-line max-len
        if (
          (Number(each.daysOfStorage) === each.daysOfStorage && each.daysOfStorage % 1 !== 0) ===
          false
        ) {
          each.DaysOfStorage = `${each.DaysOfStorage}.0`;
        }
        if (each.isPopular === 0) {
          each.isPopular = '';
        } else if (each.isPopular === 1) {
          each.isPopular = 'popular';
        }
      });
      if (userSubscription.planInfo.length > 0) {
        // setHeaderColWidth(75 / userSubscription.planInfo.length);
        setBodyColWidth(70 / userSubscription.planInfo.length);
      }
      planInfoArr.map((each) => temp.push(Object.values(each)));
      setPlanInfoValues(temp);
      planInfoArr[0] && setPlanInfoKeys(Object.keys(planInfoArr[0] || {}));
      setLoader(false);
    }
  }, [userSubscription]);

  const handleManagePlans = (menu) => onSelectedMenu(menu);

  const tableCellText = {
    fontWeight: 'bold',
    borderBottom: 'none',
    padding: 0,
  };

  const greyBackground = {
    background: '#000000',
    color: '#F1F1F1',
  };

  const managePlansButton = {
    // width: '90%',
    marginBottom: 10,
  };

  const tableMargin = {
    marginBottom: 10,
  };

  const tableContent = {
    borderBottom: 'none',
    fontWeight: 'bold',
    padding: 14,
  };

  const displayFlex = {
    display: 'flex',
    alignItems: 'center',
  };

  const blackTickImage = {
    width: 11,
    height: 11,
  };

  const [free, deluxe, premium] = planList;

  if (!free || !deluxe || !premium) return <LoadingSpinner spinnerConfig={{ showSpinner: true }} />;

  return (
    <>
      <LoadingSpinner spinnerConfig={{ showSpinner: isLoader }} />
      <>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" width="25%">
                {/* GET ONE MONTH FREE WHEN YOU PAY YEARLY! */}
                <ToggleButtonGroup
                  value={durration}
                  exclusive
                  onChange={(e, v) => v && setDurration(v)}
                  aria-label="text alignment"
                >
                  <ToggleButton value="month" aria-label="left aligned">
                    Monthly
                  </ToggleButton>
                  <ToggleButton value="year" aria-label="centered">
                    Annual
                  </ToggleButton>
                </ToggleButtonGroup>
              </TableCell>

              <TableCell
                align="center"
                style={tableCellText}
                // width={`${headerColWidth}%`}
              >
                <div>
                  {free.isPopular ? <div style={greyBackground}>Most Popular</div> : ''}
                  <div
                    style={{
                      marginTop: free.isPopular ? 0 : 24,
                      maxWidth: planList.length > 1 && 220,
                    }}
                  >
                    Free
                    <div>
                      <div>Included</div>
                      {free.PlanDescription !== 'Included' ? (
                        <ButtonElem
                          btnColor="YellowButton"
                          value="Manage Plans"
                          clicked={() => handleManagePlans(1)}
                          style={managePlansButton}
                        />
                      ) : (
                        <div style={tableMargin} />
                      )}
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell align="center" style={tableCellText}>
                <div>
                  {deluxe.isPopular ? <div style={greyBackground}>Most Popular</div> : ''}
                  <div
                    style={{
                      marginTop: deluxe.isPopular ? 0 : 24,
                      maxWidth: planList.length > 1 && 220,
                    }}
                  >
                    Deluxe
                    <div>
                      <div>{`$${deluxe[durration]}/${durration} per camera`}</div>
                      {deluxe.PlanDescription !== 'Included' ? (
                        <ButtonElem
                          btnColor="YellowButton"
                          value="Manage Plans"
                          clicked={() => handleManagePlans(1)}
                          style={managePlansButton}
                        />
                      ) : (
                        <div style={tableMargin} />
                      )}
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell
                align="center"
                style={tableCellText}
                // width={`${headerColWidth}%`}
              >
                <div style={{ background: premium.isPopular ? '#F1F1F1' : '' }}>
                  {premium.isPopular ? <div style={greyBackground}>Most Popular</div> : ''}
                  <div
                    style={{
                      marginTop: premium.isPopular ? 0 : 24,
                      maxWidth: planList.length > 1 && 220,
                    }}
                  >
                    Premium
                    <div>
                      <div>{`$${premium[durration]}/${durration} up to 3 cameras`}</div>
                      {premium.PlanDescription !== 'Included' ? (
                        <ButtonElem
                          btnColor="YellowButton"
                          value="Manage Plans"
                          clicked={() => handleManagePlans(1)}
                          style={managePlansButton}
                        />
                      ) : (
                        <div style={tableMargin} />
                      )}
                    </div>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planInfoKeys.map((each, index) => (
              <React.Fragment key={`planInfoKey ${index}`}>
                {each !== 'isPopular' ? (
                  <TableRow style={index % 2 ? { background: '#F1F1F1' } : { background: 'white' }}>
                    <TableCell align="center" style={tableContent} width="30%">
                      <div style={displayFlex}>
                        <SmartLifePlansTooltip content={planInfoMappingKeys[each]} />
                        {planInfoMappingKeys[each]}
                      </div>
                    </TableCell>
                    {planInfoValues.map((eachVal, i) => (
                      <TableCell
                        key={`planInfoValue ${i}`}
                        align="center"
                        width={`${bodyColWidth}%`}
                        style={{
                          borderBottom: 'none',
                          fontWeight: 'bold',
                          background: eachVal.includes('popular')
                            ? index % 2 === 1
                              ? '#CCCCCC'
                              : '#F1F1F1'
                            : index % 2 === 1
                            ? '#F1F1F1'
                            : 'white',
                        }}
                      >
                        {typeof eachVal[index] === 'string' ? (
                          eachVal[index].includes('.') ? (
                            <div>{`${parseInt(eachVal[index], 10)} days`}</div>
                          ) : (
                            <>{eachVal[index]}</>
                          )
                        ) : eachVal[index] === 1 ? (
                          <img src={iconEle.blackTickIcon} alt="Yes" style={blackTickImage} />
                        ) : (
                          ''
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ) : (
                  <TableRow />
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  userSubscription: state.subscriptionReducer.userSubscription,
});

const mapDispatchToProps = (dispatch) => ({
  onSelectedMenu: (data) => dispatch(profileActionCreator.setProfileMenu(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SmartLifePlans));
