import React from 'react';
import get from 'lodash/get';
import Amplify, { Auth, Storage, PubSub } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
import logo from '../assets/images/VerticalLogo.png';
import backgroundImage from '../assets/images/BackgroundImage.png';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import axios from 'axios';

const {
  REACT_APP_COGNITO_IDENTITY_POOl_ID,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_APP_CLIENT_ID,
  REACT_APP_COGNITO_MQTT_ID,
} = process.env;

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    identityPoolId: REACT_APP_COGNITO_IDENTITY_POOl_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    region: 'us-east-1',
    userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
    mqtt_id: REACT_APP_COGNITO_MQTT_ID,
  },
  Storage: {
    AWSS3: {
      region: 'us-east-1',
      bucket: process.env.REACT_APP_STORAGE_PROFILE_BUCKET,
    },
  },
});

PubSub.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: 'us-east-1',
    aws_pubsub_endpoint: REACT_APP_COGNITO_MQTT_ID,
  })
);

export const AuthContext = React.createContext({});
export const AuthProvider = (props) => {
  const [user, setUser] = React.useState();
  const [awsConfig, setAwsConfig] = React.useState();
  const [authState, setAuthState] = React.useState();
  const [credentials, setCredentials] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const location = useLocation();

  React.useEffect(() => {
    return onAuthUIStateChange(async (nextAuthState, userData) => {
      setAuthState(nextAuthState);

      const { data: cognitoUser } =
        (await axios.get('/v1/cognito-user').catch(console.error)) || {};

      if (nextAuthState === AuthState.SignedIn && userData) {
        setLoading(true);

        const credentials = await Auth.currentCredentials();
        const photo = await getProfilePhoto(credentials.identityId);

        const data = {
          createdAt: cognitoUser?.UserCreateDate,
          idToken: get(userData, 'signInUserSession.idToken.jwtToken'),
          refreshToken: get(userData, 'signInUserSession.refreshToken.token'),
          userEmail: userData.attributes.email,
          userName: userData.attributes['custom:firstName'],
          termsOfService: userData.attributes['custom:tnc_version'],
          privacyPolicy: userData.attributes['custom:pp_version'],
          IdentityId: credentials.identityId,
          photo,
        };

        const config = {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
          endpoint: process.env.REACT_APP_COGNITO_END_POINT,
          region: 'us-east-1',
        };

        setUser(data);
        setCredentials(userData);
        setAwsConfig(config);
        setLoading(false);
      } else if (nextAuthState === AuthState.SignedOut) {
        setUser(null);
      }
    });
  }, []);

  const updateName = React.useCallback(
    async (name) => {
      await Auth.updateUserAttributes(credentials, { 'custom:firstName': name });
      setUser({ ...user, userName: name });
    },
    [credentials, user]
  );

  const agreeToTerms = React.useCallback(async () => {
    const agreedAt = new Date();
    await Auth.updateUserAttributes(credentials, {
      'custom:tnc_version': agreedAt,
      'custom:pp_version': agreedAt,
    });
    setUser({ ...user, termsOfService: agreedAt, privacyPolicy: agreedAt });
  }, [credentials, user]);

  const updatePassword = React.useCallback(
    async (password) => {
      await Auth.changePassword(credentials, password);
      setUser({ ...user, userName: name });
    },
    [credentials, user]
  );

  const getProfilePhoto = async (IdentityId) => {
    return Storage.get(`profile/${IdentityId}/profilepicture.jpeg`, {
      level: 'public',
      bucket: process.env.REACT_APP_STORAGE_PROFILE_BUCKET,
    });
  };

  const uploadProfilePhoto = async (file) => {
    const fileName = `profile/${user.IdentityId}/profilepicture.jpeg`;

    return Storage.put(fileName, file, {
      bucket: process.env.REACT_APP_STORAGE_PROFILE_BUCKET,
      level: 'public',
    });
  };

  // redirects user to signup
  const queryString = get(location, 'search');

  let initialAuthState = AuthState.SignIn;

  if (queryString) {
    const [, str] = queryString.split('?');
    const { signup } = qs.parse(str);

    if (signup) {
      initialAuthState = AuthState.SignUp;
    }
  }
  // ------------------------------- //
  return (
    <AuthContext.Provider
      value={{
        user,
        awsConfig,
        updateName,
        updatePassword,
        signOut: async () => {
          await Auth.signOut({ global: true });
          window.location.reload();
        },
        uploadProfilePhoto,
        agreeToTerms,
      }}
    >
      {authState === AuthState.SignedIn && user ? (
        <React.Fragment>{loading ? <LoadingScreen /> : props.children}</React.Fragment>
      ) : (
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              width: '100vw',
              paddingTop: 50,
            }}
          >
            <img src={logo} style={{ height: 120 }} />
          </div>
          <AmplifyAuthenticator usernameAlias="email" initialAuthState={initialAuthState}>
            <AmplifySignIn
              slot="sign-in"
              formFields={[
                {
                  type: 'email',
                  label: 'Email',
                  placeholder: 'Enter your email address',
                  required: true,
                  inputProps: {
                    oninput: function () {
                      this.value = this.value.toLowerCase();
                    },
                  },
                },
                {
                  type: 'password',
                  label: 'Password',
                  placeholder: 'Enter your password',
                  required: true,
                },
              ]}
            />
            <AmplifySignIn
              slot="confirm-sign-up"
              headerText="We sent you a confirmation link in your email. Please check your email to confirm your account."
              formFields={[
                {
                  type: 'email',
                  label: 'Email',
                  placeholder: 'Enter your email address',
                  required: true,
                  inputProps: {
                    oninput: function () {
                      this.value = this.value.toLowerCase();
                    },
                  },
                },
                {
                  type: 'password',
                  label: 'Password',
                  placeholder: 'Enter your password',
                  required: true,
                },
              ]}
            />
            <AmplifySignUp
              slot="sign-up"
              usernameAlias="email"
              formFields={[
                {
                  label: 'Name',
                  type: 'custom:firstName',
                  key: 'custom:firstName',
                  placeholder: 'Enter your name',
                  required: true,
                },
                {
                  type: 'email',
                  label: 'Email',
                  placeholder: 'Enter your email address',
                  required: true,
                  inputProps: {
                    oninput: function () {
                      this.value = this.value.toLowerCase();
                    },
                  },
                },
                {
                  type: 'password',
                  label: 'Password',
                  placeholder: 'Enter a new password',
                  required: true,
                },
              ]}
            />
          </AmplifyAuthenticator>
        </div>
      )}
    </AuthContext.Provider>
  );
};
