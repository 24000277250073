import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function Snackbars(props) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    props.alert.message && setOpen(true);
  }, [props.alert]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={props.alert.variant}>
          {props.alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export const AlertContext = React.createContext({});
export const AlertProvider = (props) => {
  const [alert, setAlert] = React.useState('');

  if (alert.message instanceof Error || typeof alert.message === 'object') {
    alert.message = alert.message.message;
  }

  return (
    <AlertContext.Provider
      value={{
        alert,
        setAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};
