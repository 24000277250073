import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import messaging from '../../init-fcm';
import DeviceList from '../DeviceList/DeviceList';
import Activity from '../Activity/Activity';
import ScrollArea from '../ui/ScrollBar/ScrollArea';
import * as homePageAC from './store/action-creator';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import './Home.css';
import { AuthContext } from '../../Providers/AuthProvider';

const FCMTokenFromStorage = window.localStorage.getItem('FCMToken');

const Home = (props) => {
  const { user } = React.useContext(AuthContext);
  const emailId = user.userEmail;
  const [homeGroupName, setHomeGroupName] = useState(null);

  const dispatch = useDispatch();

  const deviceList = useSelector((state) => state.deviceReducer.deviceResp);
  const isLoader = useSelector((state) => state.deviceReducer.isLoader);
  const isTokenSent = useSelector((state) => state.homePageReducer.isTokenSent);

  useEffect(() => {
    ReactGA.pageview('/home');
  }, []);

  const setFCMToken = (FCMToken) => window.localStorage.setItem('FCMToken', FCMToken);

  const sendNewToken = () => {
    if (messaging) {
      messaging
        .getToken()
        .then((currentToken) => {
          if (!isTokenSent) {
            setFCMToken(currentToken);
            dispatch(homePageAC.setDeviceToken(currentToken));
            const newTokenData = {
              action: 'register_token',
              data: {
                device_type: 'web',
                email: emailId,
                new_device_token: currentToken,
              },
            };
            homePageAC.addOrUpdateDeviceToken(newTokenData).then(
              (resp) => {
                if (resp.data.ErrorCode === 'err_0') {
                  dispatch(homePageAC.setTokenStatus(true));
                } else if (resp.data.ErrorCode === 'err_107') {
                  const oldTokenData = {
                    action: 'update_token',
                    data: {
                      device_type: 'web',
                      email: emailId,
                      new_device_token: '',
                      old_device_token: currentToken,
                    },
                  };
                  homePageAC
                    .addOrUpdateDeviceToken(oldTokenData)
                    .then((response) => {
                      console.log(response);
                      homePageAC
                        .addOrUpdateDeviceToken(newTokenData)
                        .then(() => dispatch(homePageAC.setTokenStatus(true)))
                        .catch((err) => console.log(err));
                    })
                    .catch((error) => console.log(error));
                }
              },
              (err) => console.log(err.response)
            );
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving Firebase token. ', err);
          setFCMToken('');
        });
    }
  };

  if (messaging) {
    messaging.onTokenRefresh(() => {
      const oldToken = FCMTokenFromStorage;
      messaging
        .getToken()
        .then((refreshedToken) => {
          setFCMToken(refreshedToken);
          dispatch(homePageAC.setDeviceToken(refreshedToken));
          const oldPlusNewTokenData = {
            action: 'update_token',
            data: {
              device_type: 'web',
              email: emailId,
              new_device_token: refreshedToken,
              old_device_token: oldToken,
            },
          };
          if (oldToken !== refreshedToken) {
            homePageAC.addOrUpdateDeviceToken(oldPlusNewTokenData).then(
              (resp) => {
                console.log(resp);
              },
              (err) => console.log(err.response)
            );
          }
        })
        .catch((err) => {
          console.log('Unable to retrieve refreshed token ', err);
        });
    });
  }

  // #84555 Renaming SRU from mobile apps is not reflecting web app
  // [#84559] Click on back button in device details page from any home leads to first home
  // if (deviceList && deviceList.deviceData) {
  //   onGetDevices(emailId, deviceList.deviceData.HomeId);
  // }

  useEffect(() => {
    Notification?.requestPermission()?.then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        sendNewToken();
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  }, []);

  useEffect(() => {
    if (deviceList && deviceList.deviceData) {
      setHomeGroupName(deviceList.deviceData.HomeName);
    }
  }, [deviceList]);

  return (
    <>
      <LoadingSpinner spinnerConfig={{ showSpinner: isLoader }} />

      <ScrollArea>
        <div className="homeContainerPadding">
          <div className="homeGroup">{homeGroupName}</div>
          <DeviceList {...props} />
          <Activity {...props} />
        </div>
      </ScrollArea>
    </>
  );
};

export default withRouter(Home);
