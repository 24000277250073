import Axios from 'axios';
import * as actionTypes from './action-types';
import { objectHasKey } from '../../common/utils';

export const setNotification = notifications => (
  {
    type: actionTypes.UPDATE_NOTIFICATION,
    notifications
  }
);

export const getNotification = (paramsObj, hasPrevList) => {
  const result = {
    noNotificationList: true,
    notificationData: null,
    lastEvaluatedKey: null
  };

  return dispatch => {
    const promise = new Promise((resolve, reject) => {
      Axios.post('/v1/users', paramsObj)
        .then(resp => {
          if (resp.data.ResponseCode === 200) {
            if (objectHasKey(resp.data, 'ResponseData')) {
              if (resp.data.ErrorCode === 'err_0') {
                if (hasPrevList && hasPrevList.length) {
                  result.notificationData = hasPrevList;
                  result.notificationData.push(...resp.data.ResponseData.NotificationData);
                } else {
                  result.notificationData = resp.data.ResponseData.NotificationData;
                }
              }
              if (resp.data.ErrorCode === 'err_206') {
                result.noNotificationList = true;
              } else {
                result.noNotificationList = false;
              }
              if (objectHasKey(resp.data.ResponseData, 'LastEvaluatedKey')) {
                result.lastEvaluatedKey = resp.data.ResponseData.LastEvaluatedKey;
              }
            }
          }
          resolve(resp);
          dispatch(setNotification(result));
        })
        .catch(err => reject(err));
    });
    return promise;
  };
};
