// [#84226] WiFi status was not showing in home page
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import ConnectionStatus from './ConnectionStatus';
import DevThumbnail from './DevThumbnail/DevThumbnail';
import SRUImg from '../../assets/device/sru_device.png';
import classes from './DeviceList.module.css';

const DeviceItem = (props) => {
  const { clicked, userEmail, homeID, devList } = props;
  const [deviceStatus, setDeviceStatus] = useState(null);

  useEffect(() => {
    if (homeID) {
      const params = {
        action: 'get_connection_state',
        data: {
          email: userEmail,
          home_id: homeID,
        },
      };
      Axios.post('/v1/devices', params)
        .then((resp) => {
          if (resp.data.ResponseCode === 200) {
            if (resp.data.ErrorCode === 'err_0') {
              setDeviceStatus(resp.data.ResponseData);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [homeID]);

  const hasThumbnailImg = (imageFile) => {
    let path = null;
    if (typeof imageFile === 'string') {
      path = imageFile;
    } else if (typeof imageFile === 'object' && imageFile.length) {
      // eslint-disable-next-line prefer-destructuring
      const smallImg = imageFile.find((item) => item.indexOf('320_180') > -1);
      path = smallImg || imageFile[0];
    }
    return path;
  };

  const renderThumbnailImage = (item) => {
    let thumbElem = null;
    if (item.DeviceType === 'SmartReadinessUnit') {
      thumbElem = (
        <div className={classes.imageContainer}>
          <img src={SRUImg} alt="SRU" className={classes.sruDeviceImg} />
        </div>
      );
    } else {
      thumbElem = (
        <DevThumbnail
          thumbnailLInk={hasThumbnailImg(item.DeviceThumbnail)}
          altInfo={item.DisplayName}
          type={item.DeviceType}
        />
      );
    }
    return thumbElem;
  };

  let devListComp = null;
  if (devList) {
    devListComp = devList.map((item) => (
      <div
        className={classes.items}
        onClick={() => clicked(item)}
        role="presentation"
        key={item.DeviceId}
      >
        <ConnectionStatus
          deviceId={item.DeviceId}
          deviceStatus={deviceStatus}
          plan={item.Subscription}
        />
        {renderThumbnailImage(item)}
        <div className={classes.text}>{item.DisplayName}</div>
      </div>
    ));
  }

  return devListComp;
};

export default DeviceItem;
