import Axios from 'axios';
import * as actionTypes from './action-types';
import ErrorCodes from '../../common/ErrorCodes';

export const updateIntiateSession = (isIntiated) => ({
  type: actionTypes.INTIATE_SESSION,
  isIntiated,
});

export const setSessionId = (sessID) => ({
  type: actionTypes.SET_SESSION_ID,
  sessID,
});

export const setToken = (tokenId) => ({
  type: actionTypes.SET_TOKEN,
  tokenId,
});

export const getToken = (value) => (dispatch) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('/v1/livestream', {
      action: 'get_session_token',
      data: value,
    })
      .then((resp) => {
        const result = {
          tokboxToken: null,
          errorCode: null,
          errorMsg: null,
        };

        const respCode = resp.data.ResponseCode;
        const errCode = resp.data.ErrorCode;

        if (respCode === 200 && errCode === 'err_0') {
          result.tokboxToken = resp.data.ResponseData.session_token;
          dispatch(setToken(result.tokboxToken));
        } else {
          result.errorCode = errCode;
          result.errorMsg = ErrorCodes[errCode];
        }
        resolve(result);
      })
      .catch(() => reject(ErrorCodes.other));
  });
  return promise;
};

export const resetSession = () => ({
  type: actionTypes.RESET_SESSION,
});

export const setPublishAudio = (audioStatus) => ({
  type: actionTypes.SET_PUBLISH_AUDIO,
  audioStatus,
});

export const setScreenShot = (screenShot) => ({
  type: actionTypes.SET_SCREENSHOT,
  screenShot,
});
