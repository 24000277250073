import Axios from 'axios';
import * as actionTypes from './action-types';

export const fetchUserSubscription = resp => (
  {
    type: actionTypes.GET_USER_SUBSCRIPTION,
    subscResp: resp
  }
);
export const paymentComplete = resp => (
  {
    type: actionTypes.PAYMENT_COMPLETED,
    isComplete: resp
  }
);

export const getUserSubscription = emailId => dispatch => {
  const promise = new Promise((resolve, reject) => {
    const deviceSubscription = {
      SelectedPlanDuration: '',
      CustomerID: '',
      deviceData: [],
      planInfo: [],
      planDetails: []
    };
    Axios.get(`/v1/users?email=${emailId}&action=device_subscriptions`)
      .then(resp => {
        if (resp.data.ResponseCode === 200) {
          deviceSubscription.CustomerID = resp.data.ResponseData.CustomerID;
          deviceSubscription.deviceData = resp.data.ResponseData.DeviceData;
          deviceSubscription.planDetails = resp.data.ResponseData.PlanDetails;
          deviceSubscription.planInfo = resp.data.ResponseData.PlanInfo;
          deviceSubscription.SelectedPlanDuration = resp.data.ResponseData.SelectedPlanDuration;
          resolve(resp.data);
        }
        dispatch(paymentComplete(false));
        dispatch(fetchUserSubscription(deviceSubscription));
      })
      .catch(err => {
        reject(err);
      });
  });
  return promise;
};

export const updateUserSubscription = resp => (
  {
    type: actionTypes.UPDATE_SUBSCRIPTION,
    status: resp
  }
);

export const addPaymentSource = resp => (
  {
    type: actionTypes.ADD_PAYMENT_SOURCE,
    status: resp
  }
);

export const submitCbToken = data => dispatch => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('/v1/users', {
      action: 'create_payment_source',
      data
    })
      .then(resp => {
        if (resp.data.ResponseCode === 200) {
          resolve(resp.data);
          dispatch(addPaymentSource('success'));
        }
      })
      .catch(err => {
        reject(err);
        dispatch(addPaymentSource('fail'));
      });
  });
  return promise;
};

/**
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.deviceId
 * @param {string} data.planId
 */
export const updateSubscription = data => dispatch => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('/v2/subscriptions', data)
      .then(resp => {
        resolve(resp.data);
        dispatch(updateUserSubscription('success'));
        dispatch(paymentComplete(true));
      })
      .catch(err => {
        reject(err);
        dispatch(updateUserSubscription('fail'));
      });
  });
  return promise;
};

export const getExistingCardDetails = data => (
  {
    type: actionTypes.RETRIEVE_EXISTING_CARD,
    data
  }
);

export const retrieveCardDetils = data => dispatch => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('/v1/users', {
      action: 'retrieve_card_details',
      data
    })
      .then(resp => {
        if (resp.data.ResponseCode === 200) {
          resolve(resp.data);
          dispatch(getExistingCardDetails(resp.data.ResponseData));
        }
      })
      .catch(err => {
        reject(err);
      });
  });
  return promise;
};
