import React from 'react';
import ReactGA from 'react-ga';
import * as ROUTES from './constants/routes';
import { Route, Switch } from 'react-router-dom';
import { AuthProvider } from './Providers/AuthProvider';
import { AlertProvider } from './Providers/AlertProvider';
import AuthenticatedRoot from './AuthenticatedRoot';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import TermsOfService from './components/Profile/ProfileContent/TermsOfService.js';
import PrivacyPolicy from './components/Profile/ProfileContent/PrivacyPolicy.js';

const composeEnhancers =
  process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;

const reduxStore = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

const App = () => {
  React.useEffect(() => {
    ReactGA.initialize('UA-156099705-1');
  }, []);

  return (
    <Switch>
      <Route exact path={ROUTES.TERMS_OF_SERVICE} component={TermsOfService} />
      <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />

      <Route
        path={ROUTES.HOME}
        render={() => (
          <AuthProvider>
            <AlertProvider>
              <ReduxProvider store={reduxStore}>
                <AuthenticatedRoot />
              </ReduxProvider>
            </AlertProvider>
          </AuthProvider>
        )}
      />
    </Switch>
  );
};

export default App;
