import Axios from 'axios';
import * as actionTypes from './action-types';
import ErrorCodes from '../../common/ErrorCodes';
import { objectHasKey } from '../../common/utils';

export const setDeviceIds = (deviceIds) => ({
  type: actionTypes.SET_DEVICE_IDS,
  deviceIds,
});

export const fetchEventsList = (resp) => ({
  type: actionTypes.GET_EVENT_LIST,
  evtList: resp,
});

export const setIsAllActivity = (data) => ({
  type: actionTypes.SET_IS_ALL_ACTIVITY,
  isAllActivity: data,
});

const homePageActivityList = (dispatch, result) => {
  if (result.eventData) {
    const activitiesArray = [];
    for (let i = 0; i < result.eventData.length; i += 1) {
      activitiesArray.push(...result.eventData[i].Activities);
    }
    // result.eventData = activitiesArray.slice(0, 5);
    result.eventData = activitiesArray;
  }
  dispatch(fetchEventsList(result));
};

export const fetchAllEvents = (resp) => ({
  type: actionTypes.SET_ALL_EVENTS,
  allEvts: resp,
});

const activityListAll = (dispatch, result, evaKey) => {
  if (Object.keys(evaKey).length) {
    result.lastEvaluatedKey = evaKey;
  }
  dispatch(fetchAllEvents(result));
};

export const getEventList = (paramsObj, hasPrevEventList) => {
  const result = {
    noEventList: true,
    eventData: null,
    lastEvaluatedKey: null,
    errorCode: null,
    errorMsg: null,
    deviceList: null,
  };

  return (dispatch) => {
    const promise = new Promise((resolve, reject) => {
      Axios.post('/v1/devices', paramsObj)
        .then((resp) => {
          if (resp.data.ResponseCode === 200 && resp.data.ErrorCode === 'err_0') {
            if (hasPrevEventList && hasPrevEventList.length) {
              const prevEventList = hasPrevEventList;
              const newEventList = resp.data.ResponseData.EventData;

              newEventList.map((list) => {
                const filteredList = prevEventList.filter(
                  (list2) => list2.DateGroup === list.DateGroup
                );
                if (filteredList.length) {
                  filteredList[0].Activities.push(...list.Activities);
                } else {
                  prevEventList.push(list);
                }
                return true;
              });
              result.eventData = prevEventList;
            } else {
              result.eventData = resp.data.ResponseData.EventData;
            }
            if (resp.data.ResponseData.EventData.length) {
              result.noEventList = false;
            }

            if (objectHasKey(resp.data.ResponseData, 'DeviceList')) {
              result.deviceList = resp.data.ResponseData.DeviceList;
            }

            if (paramsObj.data.event_count === 5) {
              homePageActivityList(dispatch, result);
            } else {
              let evaKey = {};
              if (objectHasKey(resp.data.ResponseData, 'LastEvaluatedKey')) {
                evaKey = resp.data.ResponseData.LastEvaluatedKey;
              }
              activityListAll(dispatch, result, evaKey);
            }
          } else {
            result.errorCode = resp.data.ErrorCode;
            result.errorMsg = ErrorCodes[resp.data.ErrorCode];
            if (paramsObj.data.event_count === 5) {
              dispatch(fetchEventsList(result));
            } else {
              dispatch(fetchAllEvents(result));
            }
          }
          resolve(result);
        })
        .catch(() => {
          reject(ErrorCodes.other);
          if (paramsObj.data.event_count === 5) {
            dispatch(fetchEventsList(result));
          } else {
            dispatch(fetchAllEvents(result));
          }
        });
    });
    return promise;
  };
};

export const selectedEvent = (selectedEvt) => ({
  type: actionTypes.SELECTED_EVENT,
  selectedEvt,
});

export const fetchEventVideoStream = (params) => {
  const result = {
    streamVideoPath: null,
    errorCode: null,
    errorMsg: null,
  };
  const promise = new Promise((resolve, reject) => {
    Axios.post('/v1/devices', params)
      .then((resp) => {
        if (resp.data.ResponseCode === 200 && resp.data.ErrorCode === 'err_0') {
          result.streamVideoPath = resp.data.ResponseData[0].hls_url;
        } else {
          result.errorCode = resp.data.ErrorCode;
          result.errorMsg = resp.data.body;
        }
        resolve(result);
      })
      .catch((err) => reject(err));
  });

  return promise;
};

export const downloadVideo = (params, isShareVideo) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('/v1/savevideo', params, { timeout: 1000 * 300 }) // wait 5 minute before timing out
      .then((resp) => {
        const result = {
          errorCode: null,
          errorMsg: null,
          shareVideoPath: null,
        };
        if (resp.data.responseCode === 200 && resp.data.errorCode === 'err_0') {
          if (objectHasKey(resp.data, 'responseData')) {
            const videoPath = resp.data.responseData;
            if (isShareVideo) {
              result.shareVideoPath = videoPath;
            } else {
              const link = document.createElement('a');
              link.target = '_blank';
              link.href = videoPath;
              link.click();
              setTimeout(() => {
                link.remove();
              }, 500);
            }
          }
        } else {
          result.errorMsg = resp.data.body;
        }
        resolve(result);
      })
      .catch(() => {
        reject(ErrorCodes.other);
      });
  });

  return promise;
};

export const addToFavorite = (params) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('/v1/devices', params)
      .then((resp) => {
        const result = {
          errorCode: null,
          errorMsg: null,
        };
        const respCode = resp.data.ResponseCode;
        const errCode = resp.data.ErrorCode;
        if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
          result.errorCode = errCode;
          result.errorMsg = ErrorCodes[errCode];
        }
        resolve(result);
      })
      .catch(() => reject(ErrorCodes.other));
  });

  return promise;
};

export const updateFavourite = (data, status, actId) => {
  const favData = { ...data };
  // eslint-disable-next-line array-callback-return
  favData.eventData.map((events) => {
    const evtsList = events.Activities;
    // eslint-disable-next-line array-callback-return
    evtsList.map((list) => {
      if (list.ActivityID === actId) {
        list.IsFavourite = status;
      }
    });
  });

  return (dispatch) => {
    dispatch(fetchAllEvents(favData));
  };
};

export const setDeleteVideo = (deleteVideoStatus) => ({
  type: actionTypes.DELETE_VIDEO,
  deleteVideoStatus,
});

export const deleteVideo = (params) => (dispatch) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('/v1/devices', params)
      .then((resp) => {
        const result = {
          errorCode: null,
          errorMsg: null,
        };
        if (resp.data.ResponseCode === 200) {
          if (resp.data.ErrorCode === 'err_0') {
            dispatch(fetchAllEvents(null));
            dispatch(setDeleteVideo(true));
          } else {
            result.errorCode = resp.data.ErrorCode;
            result.errorMsg = resp.data.body;
            dispatch(setDeleteVideo(false));
          }
        }
        resolve(result);
      })
      .catch(() => {
        const errors = 'Something went wrong 1';
        reject(errors);
        dispatch(setDeleteVideo(false));
      });
  });
  return promise;
};

export const setFilter = (filterStatus) => ({
  type: actionTypes.UPDATE_FILTER,
  filterStatus,
});

export const updateFilter = (data, type, status) => {
  const updatedFilter = { ...data };
  updatedFilter[type] = status;
  return (dispatch) => dispatch(setFilter(updatedFilter));
};

export const resetFilter = () => ({
  type: actionTypes.RESET_FILTER,
});

export const resetDateFilter = (data) => {
  const updatedFilter = { ...data };
  updatedFilter.date = null;
  return (dispatch) => dispatch(setFilter(updatedFilter));
};

export const getDevPlanInfo = (data) => ({
  type: actionTypes.GET_DEVICE_PLAN_INFO,
  devPlanDetails: data,
});

export const getDevicePlanInfo = (data) => (dispatch) => {
  Axios.post('/v1/users', {
    action: 'retrieve_plan_details',
    data,
  })
    .then((response) => {
      dispatch(getDevPlanInfo(response.data.ResponseData));
    })
    .catch((err) => console.log('something went wrong 2', err));
};

export const setStarred = (starred) => ({
  type: actionTypes.SET_STARRED,
  starred,
});

export const setFilterIconActive = (filterIconActive) => ({
  type: actionTypes.SET_FILTER_ICON_ACTIVE,
  filterIconActive,
});

export const deleteSingleActivity = (params) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('/v1/devices', params)
      .then((resp) => {
        const result = {
          errorCode: null,
          errorMsg: null,
        };
        const respCode = resp.data.ResponseCode;
        const errCode = resp.data.ErrorCode;
        if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
          result.errorCode = errCode;
          result.errorMsg = ErrorCodes[errCode];
        }
        resolve(result);
      })
      .catch(() => reject(ErrorCodes.other));
  });

  return promise;
};

export const setActiveDevices = (activeDevices) => ({
  type: actionTypes.SET_ACTIVE_DEVICES,
  activeDevices,
});

export const setFilterData = (filterData) => ({
  type: actionTypes.SET_FILTER_DATA,
  filterData,
});

export const setActivityUpdated = (activityUpdated) => ({
  type: actionTypes.SET_ACTIVITY_UPDATED,
  activityUpdated,
});

export const setNoActivityVideo = (noActivityVideo) => ({
  type: actionTypes.SET_NO_ACTIVITY_VIDEO,
  noActivityVideo,
});
