import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import Fade from '@material-ui/core/Fade';
import * as ROUTES from '../../../constants/routes';
import * as actionCreator from '../../DeviceList/store/action-creator';
import * as homePageActionCreator from '../../Home/store/action-creator';
import ScrollArea from '../../ui/ScrollBar/ScrollArea';
import { checkmarkActiveIcon } from '../../ui/Icons/Icons';

import CustomDialog from '../../ui/CustomDialog/CustomDialog';
import StringTooltip from '../../ui/StringTooltip';
import ProfilePhoto from '../../Profile/ProfilePhoto';
import { TrunLine } from '../../common/utils';
import msgConstant from '../../common/textConstants';
import profilePic from '../../../assets/images/ProfilePhoto.png';
import versionFile from '../../../webAppVersion.txt';
import classes from './DropdownMenu.module.css';
import { AuthContext } from '../../../Providers/AuthProvider';

const DropdownMenu = (props) => {
  const { deviceList, onGetDevices, deviceToken, onAddOrUpdateDeviceToken } = props;
  const { signOut, user } = React.useContext(AuthContext);
  const imgClassList = [classes.Profile, 'CursorPointer'].join(' ');
  const logoutClassList = [classes.menu_item, classes.logout].join(' ');
  const [devices, setDevices] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [homes, setHomes] = useState(null);

  const [name, setName] = useState(user.userName);
  const [signOutModal, setSignOutModal] = useState(false);
  const [version, setVersion] = useState(null);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    deviceList && setDevices(deviceList);
    deviceList && setHomes(deviceList.homeList);
  }, [deviceList]);

  useEffect(() => {
    fetch(versionFile)
      .then((resp) => resp.text())
      .then((data) => setVersion(data));
  }, []);

  useEffect(() => setName(user.userName), [user]);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const pageHandler = (type) => {
    switch (type) {
      case 'manageHomes':
        props.history.push(ROUTES.MANAGE_HOMES);
        break;
      case 'accountSetting':
        props.history.push(ROUTES.PROFILE);
        break;
      case 'notification':
        props.history.push(ROUTES.NOTIFICATIONS);
        break;
      case 'help':
        window.open('https://www.bodyguardz.com/customer_care.html', '_blank');
        break;
      case 'signout':
        setSignOutModal(!signOutModal);
        break;
      default:
        break;
    }
    handleClose();
  };

  const handleLogOut = async () => {
    await signOut();

    const deviceTokenData = {
      action: 'update_token',
      data: {
        email: user.userEmail,
        device_type: 'web',
        new_device_token: '',
        old_device_token: deviceToken,
      },
    };
    await onAddOrUpdateDeviceToken(deviceTokenData);
  };

  const signOutBtnHandler = (hasAction) => {
    if (hasAction) {
      handleLogOut();
    } else {
      pageHandler('signout');
    }
  };

  const signOutDialogConfig = {
    open: signOutModal,
    btnClicked: signOutBtnHandler,
    btnText: 'Log Out',
    title: 'Log Out',
    disabled: true,
    type: 'action',
    btnCancel: 'Stay logged in',
    cancelBtnWidth: '130px',
    doneBtnWidth: '130px',
  };

  const getDevices = (id) => {
    handleClose();
    onGetDevices(user.userEmail, user.IdentityId, id, true).then(
      (resp) => {
        if (resp.errorMsg) {
          setCommonError(true);
          setCommonErrorMsg(resp.errorMsg);
        } else {
          props.history.push(ROUTES.HOME);
        }
      },
      (err) => {
        setCommonError(true);
        setCommonErrorMsg(err);
      }
    );
  };

  let homeList = null;
  if (devices) {
    if (homes && homes.length) {
      homeList = homes.map((list) => (
        <div
          className={classes.menu_item}
          key={list?.HomeID}
          onClick={() => getDevices(list?.HomeID)}
          role="presentation"
        >
          {devices.deviceData?.HomeId === list?.HomeID ? (
            <img
              src={checkmarkActiveIcon}
              alt={list.HomeName}
              className={classes.checkMarkActive}
            />
          ) : null}
          {list.HomeName && (
            <StringTooltip val={list.HomeName} length={27}>
              <span>{TrunLine(list.HomeName, 27)}</span>
            </StringTooltip>
          )}
        </div>
      ));
    }
  }

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: () => setCommonError(!commonError),
  };

  const renderDialog = (config, msg) => (
    <CustomDialog dialogConfig={config}>
      <div>{msg}</div>
    </CustomDialog>
  );

  return (
    <>
      <img
        src={user.photo}
        alt="Profile Pic"
        className={imgClassList}
        aria-owns={open ? 'fade_menu' : undefined}
        variant="contained"
        onClick={handleClick}
        onError={(e) => (e.target.src = profilePic)}
        role="presentation"
      />
      {anchorEl && <span className={[classes.arrowPointer, classes.upArrow].join(' ')} />}
      <Popover
        id="fade_menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={Fade}
        className={classes.fade_menu}
      >
        <ScrollArea scrollClass={classes.profile_menu_block} scrollMaxHeight="calc(100vh - 7em)">
          <div className={classes.menu_item} style={{ cursor: 'auto' }}>
            <ProfilePhoto path={profilePic} profileType="bucket" />
            {name && (
              <StringTooltip val={user.userName} length={20}>
                <div className={classes.ellipsisText}>{TrunLine(user.userName, 20)}</div>
              </StringTooltip>
            )}
            <div className="Font11">
              {user.userEmail && (
                <StringTooltip val={user.userEmail} length={27}>
                  <div>{TrunLine(user.userEmail, 27)}</div>
                </StringTooltip>
              )}
            </div>
          </div>
          <div className={[classes.menu_item, classes.version].join(' ')}>
            Version &nbsp;
            {version}
          </div>
          <div className={classes.HorLine} />
          {homeList}
          <div
            className={[classes.menu_item, classes.manage_homes].join(' ')}
            onClick={() => pageHandler('manageHomes')}
            role="presentation"
          >
            Manage Homes &gt;
          </div>
          <div className={classes.HorLine} />
          <div
            className={classes.menu_item}
            onClick={() => pageHandler('accountSetting')}
            role="presentation"
          >
            Account Settings
          </div>
          <div
            className={classes.menu_item}
            onClick={() => pageHandler('notification')}
            role="presentation"
          >
            Notifications
          </div>
          <div
            className={classes.menu_item}
            onClick={() => pageHandler('help')}
            role="presentation"
          >
            Help
          </div>
          <div
            className={logoutClassList}
            onClick={() => pageHandler('signout')}
            role="presentation"
          >
            Log Out
          </div>
        </ScrollArea>
      </Popover>
      {signOutModal && renderDialog(signOutDialogConfig, msgConstant.LOGOUT_CHECK)}
      {commonError && renderDialog(commonErrorDialogConfig, commonErrorMsg)}
    </>
  );
};

const mapStateToProps = (state) => ({
  deviceList: state.deviceReducer.deviceResp,
  homeListInfo: state.homeListReducer.homeInfo,
  deviceToken: state.homePageReducer.deviceToken,
  photoUpdated: state.profileReducer.photoUpdated,
});

const mapDispatchToProps = (dispatch) => ({
  onGetDevices: (emailId, IdentityId, homeId, isFromDropdown) =>
    dispatch(actionCreator.getDevices(emailId, IdentityId, homeId, isFromDropdown)),
  onAddOrUpdateDeviceToken: (data) => homePageActionCreator.addOrUpdateDeviceToken(data),
});

export default connect(mapStateToProps, mapDispatchToProps)(DropdownMenu);
